import "react-toastify/dist/ReactToastify.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { toast } from "react-toastify";
import { HouseProvider } from "../contexts/HouseContext";
import { NetworkProvider } from "../contexts/NetworkContext";
import { PlatformProvider } from "../contexts/PlatformContext";
import { ProgramProvider } from "../contexts/ProgramContext";
import { RandomnessDispatcherProvider } from "../contexts/RandomnessDispatcherContext";
import { ToasterContext } from "../contexts/ToasterContext";
import Error404Page from "../pages/Error404Page";
import { ModalProvider as ReactModalProvider } from "react-modal-hook";
import { TransitionGroup } from "react-transition-group";
import { MainContainer } from "./Containers/MainContainer";
import { OverviewPage } from "./Pages/OverviewPage";
import { PlatformPage } from "./Pages/Platform";
import { SignerProvider } from "./Contexts/SignersContext";
import { BalanceProvider } from "./Contexts/BalanceContext";

export const App = () => {
  return (
    <ToasterContext.Provider value={toast}>
      <NetworkProvider>
        <ProgramProvider>
          <RandomnessDispatcherProvider>
            <HouseProvider>
              <PlatformProvider>
                <BalanceProvider>
                  <SignerProvider>
                    <ReactModalProvider rootComponent={TransitionGroup}>
                      <BrowserRouter>
                        <Routes>
                          <Route path="" element={<MainContainer />}>
                            <Route index element={<OverviewPage />} />
                            <Route path="platform" element={<PlatformPage />} />
                            <Route path="*" element={<Error404Page backHome={``} />} />
                          </Route>
                        </Routes>
                      </BrowserRouter>
                    </ReactModalProvider>
                  </SignerProvider>
                </BalanceProvider>
              </PlatformProvider>
            </HouseProvider>
          </RandomnessDispatcherProvider>
        </ProgramProvider>
      </NetworkProvider>
    </ToasterContext.Provider>
  );
};
