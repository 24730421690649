import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { BaseModal } from "../../../components/common/modal/Modal";
import { SignerContext } from "../../Contexts/SignersContext";
import FormItem from "../../../components/common/form-item/FormItem";
import Input from "../../../components/common/input/Input";
import { Keypair } from "@solana/web3.js";
import Button from "../../../components/common/button/Button";

interface ISignerModalProps {
    visible: boolean
    hideModal: Function
}

export const AddSignerModal: FC<ISignerModalProps> = ({ visible, hideModal }) => {
    const { addSigner, loadKeypair, isOnSignersList } = useContext(SignerContext);
    const [secretkeyInput, setSecretkeyInput] = useState<string>()
    const [secretkeyInputError, setSecretkeyInputError] = useState<string>()
    const [keypair, setKeypair] = useState<Keypair>()
    const [pinInput, setPinInput] = useState<string>()
    const [pinInputError, setPinInputError] = useState<string>()
    const [noPermissions, setNoPermissions] = useState(false)

    const emptyInputs = useCallback(() => {
        setPinInput(undefined)
        setSecretkeyInput(undefined)
        setKeypair(undefined)
        setSecretkeyInputError(undefined)
        setPinInputError(undefined)
        setNoPermissions(false)
    }, [])

    useEffect(() => {
        setKeypair(undefined)

        if (secretkeyInput == null || secretkeyInput.length == 0) {
            return 
        }

        // LOAD THE KEYPAIR
        try {
            const keypairLoaded = loadKeypair(secretkeyInput)
            setKeypair(keypairLoaded)
            setSecretkeyInputError(undefined)
        } catch (err) {
            console.warn('Issue loading keypair', err)
            setSecretkeyInputError("Please enter a valid secret key")
        }
    }, [secretkeyInput])

    const authenticateWallet = useCallback(async () => {
        if (secretkeyInput == null || secretkeyInput.length == 0) {
            setSecretkeyInputError("Please enter a secret key")
            return
        } 

        if (keypair == null || keypair.publicKey.toString() == null) {
            setSecretkeyInputError("Please enter a valid secret key")
            return
        } else {
            setSecretkeyInputError(undefined)
        }

        if (pinInput == null || pinInput.length < 4) {
            setPinInputError("Please enter a pincode with min length of 4.")
            return
        } else {
            setPinInputError(undefined)
        }

        const isASigner = isOnSignersList(keypair.publicKey.toString())
        if (isASigner == false) {
            setNoPermissions(true)
            return
        } else {
            setNoPermissions(false)
        }

        try {
            await addSigner(secretkeyInput, pinInput, () => {}, (err) => {
                hideModal()
            })

            emptyInputs()
            hideModal()
        } catch (err) {}
    }, [secretkeyInput, keypair, pinInput, addSigner, hideModal, isOnSignersList, emptyInputs])

    const noPermissionsContent = useMemo(() => {
        return <div className="flex-col justify-center items-center gap-y-4 text-center p-3">
            <div className="font-white text-3xl font-oxanium">No Permissions</div>
            <div className="text-xl text-gray-300">No permissions have been granted to selected wallet. Please contact Zeebit’s team when requesting DevTools access.</div>
            <Button variant="secondary" onClick={() => {
                // WIPE ALL THE INPUTS
                emptyInputs()
            }}>
                Try Another Wallet
            </Button>
        </div>
    }, [])

    return (
        <BaseModal open={visible} onClose={hideModal} title="Authentication">
            <div className="max-h-[400px] flex flex-col justify-center items-center text-gray-400 w-full">
                {noPermissions == true ? noPermissionsContent: ''}
                {
                    noPermissions == false ? <div className="flex flex-col items-center gap-y-6 w-full">
                    {/* SECRET KEY */}
                    <div className="flex flex-col items-start gap-y-2 self-stretch">
                        <div className="text-gray-200">
                            Private Key (String)
                        </div>
                        <FormItem error={secretkeyInputError} className="flex-1 self-stretch">
                            <Input
                                error={secretkeyInputError}
                                classes={{ label: "h-[42px] flex-row", wrapper: "flex-1" }}
                                type={'string'}
                                placeholder={'Please enter private key'}
                                value={secretkeyInput || ''}
                                onChange={(e) => {
                                    setSecretkeyInput(e.target.value);
                                }}
                            />
                        </FormItem>
                    </div>

                    {/* PUB KEY */}
                    <div className="flex flex-col items-start gap-y-2 self-stretch">
                        <div className="text-gray-200">
                            Public Key
                        </div>
                        <FormItem className="flex-1 self-stretch">
                            <Input
                                classes={{ label: "h-[42px] flex-row", wrapper: "flex-1" }}
                                type={'string'}
                                value={secretkeyInput == null || secretkeyInput.length == 0 ? 'Please enter private key' : keypair?.publicKey.toString()}
                            />
                        </FormItem>
                    </div>

                    {/* PIN */}
                    <div className="flex flex-col items-start gap-y-2 self-stretch">
                        <div className="text-gray-200 ">
                            Pin
                        </div>
                        <FormItem error={pinInputError} className="flex-1 self-stretch">
                            <Input
                                error={pinInputError}
                                classes={{ label: "h-[42px] flex-row", wrapper: "flex-1" }}
                                type={'string'}
                                placeholder={'Please enter pin'}
                                value={pinInput || ''}
                                onChange={(e) => {
                                    setPinInput(e.target.value);
                                }}
                            />
                        </FormItem>
                    </div>

                    {/* AUTH WALLET BUTTON */}
                    <Button onClick={authenticateWallet} variant="secondary" className="flex-1 h-[42px]">
                        Authenticate Wallet
                    </Button>
                    </div>: ''
                }
            </div>
        </BaseModal>
    );
}