import { FC, useCallback, useContext, useEffect, useState } from "react";
import { PublicKey } from "@solana/web3.js";

import { BaseModal } from "../../../components/common";
import FormItem from "../../../components/common/form-item/FormItem";
import Button from "../../../components/common/button/Button";
import Dropdown from "../../../components/common/dropdown/Dropdown";
import PasswordInput from "../../Components/PasswordInput/PasswordInput";
import { ISigner, SignerContext } from "../../Contexts/SignersContext";
import { NetworkContext } from "../../../contexts/NetworkContext";
import { truncatePubkey } from "../../../utils/string/string";
import { ToasterContext } from "../../../contexts/ToasterContext";
import { BASE_TOAST_CONFIG, BaseToast } from "../../../components/toast/BaseToast";
import { IPlatformToken } from "../../../sdk/platform";
import { PlatformContext } from "../../../contexts/PlatformContext";
import { ICasinoToken } from "../../../types/token";
import Icon from "../../../components/common/icon/Icon";
import { HouseContext } from "../../../contexts/HouseContext";
import Input from "../../../components/common/input/Input";
import InputButton from "../../../components/common/input/InputButton";
import { withdrawPlatformRevenueTx } from "../../sdk/platform";

export interface BalanceInputProps {
  balance: number;
  setBalance: React.Dispatch<React.SetStateAction<number>>;
  icon: string
}

export const BalanceInput: FC<BalanceInputProps> = ({ balance, setBalance, icon }) => {
  const [stringBalance, setStringBalance] = useState("");

  return (
    <Input
      name="balance"
      type="number"
      step={0.1}
      min={""}
      error={""}
      value={stringBalance || String(balance)}
      onChange={(e) => {
        setBalance(Number(e.target.value));
        setStringBalance(e.target.value);
      }}
      leftInfo={<Icon iconUrl={icon} className="mr-[5px] mb-[2px]" />}
      rightInfo={
        <div className="flex gap-1 ml-[5px]">
          <InputButton
            className="px-2 text-gray-400 w-auto"
            onClick={() => {
              setBalance((prev) => {
                const newVal = Math.floor((prev * 10) / 2) / 10;
                setStringBalance(String(newVal));
                return newVal;
              });
            }}
          >
            Half
          </InputButton>
          <InputButton
            className="px-2 text-gray-400 w-auto"
            onClick={() => {
              setBalance(99999999);
              setStringBalance(String(99999999));
            }}
          >
            Max
          </InputButton>
        </div>
      }
    />
  );
};

interface IWithdrawProfitProps {
  visible: boolean;
  hideModal: Function;
  platformToken?: IPlatformToken;
  tokenContext: ICasinoToken
}

export const WithdrawProfitModal: FC<IWithdrawProfitProps> = ({
  visible,
  hideModal,
  platformToken,
  tokenContext
}) => {

  const [pinInput, setPinInput] = useState<string>();
  const [pinInputError, setPinInputError] = useState<string>();

  const { signers, getKeypair, transactionHandler } = useContext(SignerContext)
  const [selectedSigner, setSelectedSigner] = useState<ISigner>()

  useEffect(() => {
    if (selectedSigner == null && signers != null && signers.length > 0) {
      setSelectedSigner(signers[0])
    }
  }, [signers])

  const { client } = useContext(NetworkContext)
  const [loading, setLoading] = useState(false)
  const toast = useContext(ToasterContext)
  const { platform, loadPlatform } = useContext(PlatformContext)
  const { house } = useContext(HouseContext)

  const [revenueShare, setRevenueShare] = useState(0)
  const [rewardAccrual, setRewardAccrual] = useState(0)

  useEffect(() => {
    if (platformToken != null) {
      setRevenueShare(Number(platformToken.revenueShareBalanceAccrued) - Number(platformToken.revenueShareDrawndown))
      setRewardAccrual(Number(platformToken.rewardAccrualBalanceAccrued) - Number(platformToken.rewardAccrualDrawndown))
    }
  }, [
    platformToken
  ])

  const platformWithdrawRevenue = useCallback(async () => {
    if (selectedSigner == null || pinInput == null || client == null || platform == null) {
      console.error('Issue with inputs for platformWithdrawRevenue')

      return
    }

    setLoading(true)

    try {
      // UPDATE STATUS
      const keypair = getKeypair(selectedSigner.publicKey, pinInput)
      const tx = await withdrawPlatformRevenueTx(platform, keypair.publicKey, new PublicKey(platformToken?.pubkey || ''), revenueShare, rewardAccrual)

      const sig = await transactionHandler.sendAndConfirmTransaction(tx, [keypair])

      toast(
        <BaseToast
              message={`Successfully withdrew revenue: ${sig}`}
              type={"success"} />,
        BASE_TOAST_CONFIG,
      );

      // RELOAD THE PLATFORM
      if (house != null) {
        await loadPlatform(house)
      }

      // CLEAR PIN AND SIGNER
      setPinInput(undefined)
      setSelectedSigner(undefined)

      hideModal()
    } catch (err) {
      console.error({ err })
      console.error(`Issue withdrawing platform share`, err)
      toast(
        <BaseToast
          message={`Issue withdrawing platform share. ${err}`}
          type={"error"}
        />,
        BASE_TOAST_CONFIG,
      );
    }

    setLoading(false)
  }, [selectedSigner, client, pinInput, platform, revenueShare, rewardAccrual, house, transactionHandler, platformToken])

  return (
    <BaseModal open={visible} onClose={hideModal} title={`Withdraw`}>
      <div className=" flex flex-col justify-center items-center text-gray-400 w-full">

        <div className="flex flex-col items-center gap-y-4 w-full">
          {platformToken != null ? <FormItem
            className="flex-1 self-stretch"
            label="Revenue Share"
            rightLabel={
              <div className="flex gap-1.5 items-center">
                <Icon iconUrl={tokenContext.imageDarkSvg} size="sm" />
                {Number(platformToken?.revenueShareBalanceAccrued) - Number(platformToken?.revenueShareDrawndown)}
              </div>
            }
          >
            <BalanceInput balance={revenueShare} setBalance={setRevenueShare} icon={tokenContext.imageDarkSvg} />
          </FormItem>: ''}

          {platformToken != null ? <FormItem
            className="flex-1 self-stretch"
            label="Reward Accrual"
            rightLabel={
              <div className="flex gap-1.5 items-center">
                <Icon iconUrl={tokenContext.imageDarkSvg} size="sm" />
                {Number(platformToken?.rewardAccrualBalanceAccrued || '') - Number(platformToken?.rewardAccrualDrawndown || '')}
              </div>
            }
          >
            <BalanceInput balance={rewardAccrual} setBalance={setRewardAccrual} icon={tokenContext.imageDarkSvg} />
          </FormItem>: ''}

          {/* Wallet */}

          <FormItem className="flex-1 self-stretch">
            <Dropdown
              initialSelectedItem={{ name: 'Choose Signer', value: undefined }}
              items={signers.map((signer) => {
                return { name: truncatePubkey(signer.publicKey, 5), value: signer }
              })}
              onSelect={(item) => {
                setSelectedSigner(item.value)
              }}
              containerStyles="w-full"
            />
          </FormItem>

          {/* PIN */}
          <FormItem error={pinInputError} className="flex-1 self-stretch">
            <PasswordInput
              error={pinInputError}
              placeholder={"Enter PIN"}
              value={pinInput || ""}
              onChange={setPinInput}
            />
          </FormItem>

          {/* AUTH WALLET BUTTON */}
          {platformToken != null ? <Button disabled={platform == null || selectedSigner == null || pinInput == null}
            onClick={platformWithdrawRevenue} isLoading={loading} variant="secondary" size="sm">
            {`platformWithdrawRevenue`}
          </Button>: ''}
        </div>
      </div>
    </BaseModal>
  );
};
