import { FC, useCallback, useContext, useEffect, useState } from "react";
import { BaseModal } from "../../../components/common/modal/Modal";
import FormItem from "../../../components/common/form-item/FormItem";
import Button from "../../../components/common/button/Button";
import Dropdown from "../../../components/common/dropdown/Dropdown";
import Separator from "../../Components/Separator/Separator";
import PasswordInput from "../../Components/PasswordInput/PasswordInput";
import Icon from "../../../components/common/icon/Icon";
import IconFont from "../../../components/common/icon/Icon";
import { ISigner, SignerContext } from "../../Contexts/SignersContext";
import { NetworkContext } from "../../../contexts/NetworkContext";
import InputButton from "../../../components/common/input/InputButton";
import Input from "../../../components/common/input/Input";
import { truncatePubkey } from "../../../utils/string/string";
import { ToasterContext } from "../../../contexts/ToasterContext";
import { BASE_TOAST_CONFIG, BaseToast } from "../../../components/toast/BaseToast";
import { getPreIxns } from "../../sdk/utils";
import { IPlatformToken } from "../../../sdk/platform";
import { PlatformContext } from "../../../contexts/PlatformContext";
import { HouseContext } from "../../../contexts/HouseContext";
import { ICasinoToken } from "../../../types/token";

export enum TransferModalType {
    WITHDRAW = 'withdraw',
    DEPOSIT = 'deposit'
}

interface ITransferModalProps {
    visible: boolean;
    hideModal: Function;
    platformToken: IPlatformToken | undefined
    tokenContext: ICasinoToken
    type: TransferModalType | undefined
}

export const TransferModal: FC<ITransferModalProps> = ({ visible, hideModal, platformToken, tokenContext, type }) => {

    const [pinInput, setPinInput] = useState<string>();
    const [pinInputError, setPinInputError] = useState<string>();
    const [amount, setAmount] = useState<number>(0);
    const { client } = useContext(NetworkContext)
    const { platform, loadPlatform } = useContext(PlatformContext)
    const { signers, getKeypair, transactionHandler } = useContext(SignerContext)
    const [selectedSigner, setSelectedSigner] = useState<ISigner>()

    useEffect(() => {
        if (selectedSigner == null && signers != null && signers.length > 0) {
            setSelectedSigner(signers[0])
        }
    }, [signers])

    const [loading, setLoading] = useState(false)
    const toast = useContext(ToasterContext)
    const { house } = useContext(HouseContext)

    const transferTokens = useCallback(async () => {
        if (platform == null || platformToken == null || client == null || selectedSigner == null || pinInput == null) {
            return
        }

        setLoading(true)

        try {
            const keypair = getKeypair(selectedSigner?.publicKey, pinInput)
            const tx = type == TransferModalType.DEPOSIT ? await platform.depositRewardTokensTx(keypair.publicKey, amount) : await platform.withdrawRewardTokensTx(keypair.publicKey, amount)
            // ADD PRE-IXNS
            const preIxns = getPreIxns()
            tx.add(...preIxns)

            const sig = await transactionHandler.sendAndConfirmTransaction(tx, [keypair])

            toast(
                <BaseToast
                    message={`Successfully transferred reward tokens: ${sig}`}
                    type={"success"}
                />,
                BASE_TOAST_CONFIG,
            );

            if (house != null) {
                await loadPlatform(house)
            }

            hideModal()
        } catch (err) {
            console.error('Issue transferring tokens', err)
            toast(
                <BaseToast
                    message={`Issue transferring tokens: ${err}`}
                    type={"error"}
                />,
                BASE_TOAST_CONFIG,
            );
        }

        setLoading(false)
    }, [type, amount, client, platformToken, selectedSigner, getKeypair, pinInput, transactionHandler])

    return (
        <BaseModal
            open={visible}
            onClose={hideModal}
            icon={<IconFont className="text-gray-50 rotate-180" name="download" />}
            title={type == TransferModalType.DEPOSIT ? 'Deposit' : 'Withdraw'}
        >
            <div className=" flex flex-col justify-center items-center text-gray-400 w-full">

                <div className="flex flex-col items-center gap-y-4 w-full">
                    {/* Status */}
                    <FormItem
                        className="flex-1 self-stretch"
                        label="Available Balance (Atomic)"
                        rightLabel={
                            <div className="flex gap-1.5 items-center">
                                <Icon iconUrl={tokenContext?.imageDarkPng} size="sm" />
                                ({platformToken?.rewardFundingBalanceAvailable})
                            </div>
                        }
                    >
                        <BalanceInput balance={amount} setBalance={setAmount} icon={tokenContext?.imageDarkPng || ""} />
                    </FormItem>

                    <Separator />

                    {/* Wallet */}

                    <FormItem className="flex-1 self-stretch">
                        <Dropdown
                            initialSelectedItem={{ name: 'Choose Signer', value: undefined }}
                            items={signers.map((signer) => {
                                return { name: truncatePubkey(signer.publicKey, 5), value: signer }
                            })}
                            onSelect={(item) => {
                                setSelectedSigner(item.value)
                            }}
                            containerStyles="w-full"
                        />
                    </FormItem>

                    {/* PIN */}
                    <FormItem error={pinInputError} className="flex-1 self-stretch">
                        <PasswordInput
                            error={pinInputError}
                            placeholder={"Enter PIN"}
                            value={pinInput || ""}
                            onChange={setPinInput}
                        />
                    </FormItem>

                    {/* AUTH WALLET BUTTON */}
                    <Button
                        disabled={selectedSigner == null || pinInput == null || client == null}
                        isLoading={loading}
                        onClick={transferTokens}
                        variant="secondary"
                    >
                        {`${type} Tokens`}
                    </Button>
                </div>
            </div>
        </BaseModal >
    );
};

export interface BalanceInputProps {
    balance: number;
    setBalance: React.Dispatch<React.SetStateAction<number>>;
    icon: string
}

export const BalanceInput: FC<BalanceInputProps> = ({ balance, setBalance, icon }) => {
    const [stringBalance, setStringBalance] = useState("");

    return (
        <Input
            name="balance"
            type="number"
            step={0.1}
            min={""}
            error={""}
            value={stringBalance || String(balance)}
            onChange={(e) => {
                setBalance(Number(e.target.value));
                setStringBalance(e.target.value);
            }}
            leftInfo={<Icon iconUrl={icon} className="mr-[5px] mb-[2px]" />}
            rightInfo={
                <div className="flex gap-1 ml-[5px]">
                    <InputButton
                        className="px-2 text-gray-400 w-auto"
                        onClick={() => {
                            setBalance((prev) => {
                                const newVal = Math.floor((prev * 10) / 2) / 10;
                                setStringBalance(String(newVal));
                                return newVal;
                            });
                        }}
                    >
                        Half
                    </InputButton>
                    <InputButton
                        className="px-2 text-gray-400 w-auto"
                        onClick={() => {
                            setBalance(99999999);
                            setStringBalance(String(99999999));
                        }}
                    >
                        Max
                    </InputButton>
                </div>
            }
        />
    );
};
