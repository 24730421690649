import { ComputeBudgetProgram, PublicKey, SystemProgram, Transaction, TransactionInstruction } from "@solana/web3.js";
import Platform from "../../sdk/platform";
import { ASSOCIATED_TOKEN_PROGRAM_ID, NATIVE_MINT, TOKEN_PROGRAM_ID, createAssociatedTokenAccountInstruction } from "@solana/spl-token";
import { BN } from "@coral-xyz/anchor";

export const withdrawPlatformRevenueTx = async (
    platform: Platform,
    owner: PublicKey,
    tokenMintPubkey: PublicKey,
    revenueShareAmount?: number,
    rewardAccrualAmount?: number,
  ): Promise<Transaction> => {
    const oraclePubkey = platform.house.getTokenConfigAndStatistics(tokenMintPubkey)?.oracle;
    const houseTokenVaultPubkey = await platform.house.deriveHouseTokenVault(
      tokenMintPubkey
    );
    const houseTokenPubkey = platform.house.deriveHouseTokenAccountPubkey(
      tokenMintPubkey
    );
    const permissionPubkey = platform.derivePlatformPermissionAccountPubkey(
      owner
    );
    var preIxns: TransactionInstruction[] = [
      ComputeBudgetProgram.setComputeUnitLimit({
        units: 400_000, //1_000_000 
      }),
      ComputeBudgetProgram.setComputeUnitPrice({
        microLamports: 2_000
      })
    ];
    var tokenAccountOrWalletPubkey: PublicKey;
    if (tokenMintPubkey.toString() == NATIVE_MINT.toString()) {
      tokenAccountOrWalletPubkey = owner;
    } else {
      tokenAccountOrWalletPubkey = await platform.house.derivePlayerAssociatedTokenAccount(
        owner,
        tokenMintPubkey
      );
      try {
        const ataInfo = await platform.program.provider.connection.getTokenAccountBalance(
          tokenAccountOrWalletPubkey
        );
        if (ataInfo == null) {
          throw Error
        };
      } catch (e) {
        preIxns.push(
          createAssociatedTokenAccountInstruction(
            owner,
            tokenAccountOrWalletPubkey,
            owner,
            tokenMintPubkey,
            TOKEN_PROGRAM_ID,
            ASSOCIATED_TOKEN_PROGRAM_ID
          )
        );
      }
    };

    return await platform.program.methods.platformWithdrawRevenue({
      revenueShareAmount: (revenueShareAmount == undefined ? null : new BN(revenueShareAmount)),
      rewardAccrualAmount: (rewardAccrualAmount == undefined ? null : new BN(rewardAccrualAmount)),
    }).accounts({
      authority: owner,
      authorityPermission: permissionPubkey,
      house: platform.house.publicKey,
      houseToken: houseTokenPubkey,
      platform: platform.publicKey,
      tokenMint: tokenMintPubkey,
      tokenAccountOrWallet: tokenAccountOrWalletPubkey,
      vault: houseTokenVaultPubkey,
      oracle: oraclePubkey,
      tokenProgram: TOKEN_PROGRAM_ID,
      systemProgram: SystemProgram.programId,
    }).preInstructions(
      preIxns
    ).transaction()
  };