import { ConfirmOptions, Connection, Keypair, Transaction } from "@solana/web3.js"
import { sendTxnMultipleTimesToMultipleRpcs } from "./utils"
import { RPC_WRITE_ENPOINTS } from "./constants"
import { BLOCKHASH_COMMITMENT } from "../../utils/solana/rpc"

export default class TransactionHandler {
    private _numberOfTries: number
    private _clients: Connection[]
    private _confirmOptions: ConfirmOptions

    constructor(numberOfTries: number, clients: Connection[], confirmOptions: ConfirmOptions) {
        this._numberOfTries = numberOfTries;
        this._clients = clients;
        this._confirmOptions = confirmOptions
    }

    static load(clients: string[] = RPC_WRITE_ENPOINTS, confirmOptions: ConfirmOptions = {
        commitment: "processed",
        skipPreflight: true
    }, numberOfTries: number = 5): TransactionHandler {
        return new TransactionHandler(numberOfTries, clients.map((client) => new Connection(client)), confirmOptions)
    }

    async sendAndConfirmTransaction(transaction: Transaction, signers: Keypair[], numberOfTries: number = this._numberOfTries, clients: Connection[] = this._clients, options: ConfirmOptions = this._confirmOptions): Promise<string> {
        return TransactionHandler.sendAndConfirmTransaction(transaction, signers, numberOfTries, clients, options)
    }
    

    static async sendAndConfirmTransaction(transaction: Transaction, signers: Keypair[], numberOfTries: number = 5, clients: Connection[], options: ConfirmOptions = {
        commitment: "processed",
        skipPreflight: true
    }): Promise<string> {
        const blockMeta = await clients[0].getLatestBlockhash(BLOCKHASH_COMMITMENT)
        const sentTx = await this.sendTransaction(transaction, blockMeta.blockhash, numberOfTries, clients, signers, options)
        
        await clients[0].confirmTransaction({
            signature: sentTx,
            blockhash: blockMeta.blockhash,
            lastValidBlockHeight: blockMeta.lastValidBlockHeight
        }, "processed")

        return sentTx
    }

    async sendTransaction(transaction: Transaction, blockhash: string, numberOfTries: number = this._numberOfTries, clients: Connection[] = this._clients, signers: Keypair[], options: ConfirmOptions = this._confirmOptions): Promise<string> {
        return TransactionHandler.sendTransaction(transaction, blockhash, numberOfTries, clients, signers, options)
    }

    static async sendTransaction(transaction: Transaction, blockhash: string, numberOfTries: number, clients: Connection[], signers: Keypair[], options: ConfirmOptions): Promise<string> {
        return await sendTxnMultipleTimesToMultipleRpcs(transaction, blockhash, numberOfTries, clients, signers, options)
    }
}