import React, { FC } from "react";

export interface SeparatorProps {}

const Separator: FC<SeparatorProps> = ({}) => {
  return (
    <div className="w-full py-1">
      <div className="w-full border-gray-600 border-b-[1px] " />
    </div>
  );
};

export default Separator;
