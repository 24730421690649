import { Outlet } from "react-router-dom";
import { SCROLLBAR_CLASSES } from "../../styles/commonClasses";
import { ToastContainer } from "react-toastify";
import { TopNav } from "./Navs/TopNav";
import { LeftNav } from "./Navs/LeftNav";

export const MainContainer = () => {
  return (
    <>
      {/* TOP NAV ACCROSS FULL WIDTH */}
      <TopNav />

      <div>
        {/* LEFT SIDEBAR */}
        <LeftNav />

        <div className={`pl-left-bar-width`}>
          <main className="relative">
            <div
              className={`p-4 pr-3 sm:p-6 lg:px-6 lg:py-5 h-screen-minus-bar bg-global-bg scroll-fix overflow-y-scroll flex flex-col gap-5 ${SCROLLBAR_CLASSES}`}
            >
              <Outlet />
            </div>
          </main>
        </div>
      </div>

      <ToastContainer
        className="max-sm:mx-3 max-sm:mb-20 "
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={"dark"}
      />
    </>
  );
};
