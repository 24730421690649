import React, { useContext } from "react";

import { IAvatarProps, PlayerAvatar } from "./PlayerAvatar";
import { PlayerContext } from "../../../contexts/PlayerContext";

export const CurrentPlayerAvatar: React.FC<IAvatarProps> = ({
  shouldGenerateAvatar,
  ...avatarProps
}) => {
  const { playerAccount, loadedPlayerAccount } = useContext(PlayerContext)
  const hasPlayerAccount = loadedPlayerAccount && playerAccount?.state;

  return (
    <PlayerAvatar
      {...avatarProps}
      shouldGenerateAvatar={!hasPlayerAccount || shouldGenerateAvatar}
    />
  );
};