import { Fragment, useContext, useMemo } from "react";
import Button from "../../../components/common/button/Button";
import { SignerContext } from "../../Contexts/SignersContext";
import Tooltip from "../../../components/common/tooltip/Tooltip";
import { truncatePubkey } from "../../../utils/string/string";
import { NumberType, formatZeebitNumber } from "../../../utils/currency/formatting";
import SolIcon from "./../../../assets/icons/sol.svg";
import XIcon from "./../../../assets/icons/x.svg";
import Icon from "../../../components/common/icon/Icon";
import { AddSignerModal } from "../../modals/signer/AddSignerModal";
import { useModal } from "react-modal-hook";

export const Signers = () => {
  const { signers, removeSigner, clearSigners } = useContext(SignerContext);
  const [showAuthModal, hideAuthModal] = useModal(
    ({ in: open }): JSX.Element => <AddSignerModal visible={open} hideModal={hideAuthModal} />,
    [signers],
  );

  const signerContent = useMemo(() => {
    return (
      <div className="flex flex-col items-start gap-y-3">
        {/* HEADING */}
        <div className="flex w-[298px] justify-between items-start text-gray-300 text-sm">
          <div>Pubkey</div>
          <div>Balance (SOL)</div>
        </div>

        {/* ROWS */}
        <div className="flex w-[302px] flex-col items-start gap-y-2">
          {signers != null && Array.isArray(signers)
            ? signers?.map((signer, index) => {
                return (
                  <Fragment>
                    <div className="flex items-center gap-x-2 self-stretch" key={index}>
                      {/* ADDRESS + BAL */}
                      <div className="flex justify-between items-center flex-1 self-stretch">
                        <div>{truncatePubkey(signer.publicKey, 3)}</div>

                        <div className="flex justify-end items-center gap-x-4">
                          <div className="h-[14px] w-[14px]">
                            <SolIcon />
                          </div>
                          <div>
                            {formatZeebitNumber(signer.solBalance, NumberType.TOKEN_AMOUNT, 8, 9)}
                          </div>
                        </div>
                      </div>

                      {/* REMOVE WAL */}
                      <div>
                        <Button
                          onClick={() => {
                            removeSigner(signer);
                          }}
                          variant="outlined"
                          size="md"
                          className="h-[26px]"
                        >
                          {/* UPDATE WITH ICON */}
                          <Icon icon={<XIcon />} size="md" />
                        </Button>
                      </div>
                    </div>
                    <div className="h-[1px] self-stretch bg-gray-400" />
                  </Fragment>
                );
              })
            : ""}
        </div>

        {/* CTAs */}
        <div className="flex w-full justify-between">
          <Button
            variant="outlined"
            size="md"
            onClick={showAuthModal}
          >
            Add Wallet
          </Button>
          <Button
            variant="outlined"
            size="md"
            onClick={() => {
              clearSigners();
            }}
          >
            Remove All
          </Button>
        </div>
      </div>
    );
  }, [signers, removeSigner, showAuthModal, clearSigners]);

  return (
    <Tooltip showOnClick={true} content={signerContent}>
      <Button variant="secondary-gray" size="md">
        Wallets ({signers.length})
      </Button>
    </Tooltip>
  );
};
