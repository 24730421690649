import { useNavigate } from "react-router-dom";
import { Signers } from "../../Components/Signers/Signers";

export function TopNav() {
  const navigate = useNavigate();

  return (
    <ul className="flex justify-between h-top-bar-height bg-gray-800 px-4 py-0 items-center relative z-40 shadow-black-regular">
      {/* HAMBURGER AND LOGO */}
      <li className="text-white flex align-center sm:space-x-5">
        <div
          className="flex align-center cursor-pointer"
          onClick={() => {
            navigate(``);
          }}
        >
          <div className="">
            ADMIN APP
          </div>
        </div>
      </li>

      {/* CONNECT, LIST WALLETS */}
      <li className="flex">
        <Signers />
      </li>
    </ul>
  );
}
