import React, { FC, ReactNode, useState, useRef, useEffect } from "react";
import { Disclosure } from '@headlessui/react'

import IconFont from "../iconFont/IconFont";

export type AccordionItem = {
  title: string | ReactNode;
  content: string | ReactNode;
  key: string;
};

export type AccordionProps = {
  items: AccordionItem[];
  selectedItem?: string;
};

export const Accordion: FC<AccordionProps> = ({ items, selectedItem }) => {
  const accordionRef = useRef(null);
  const [ selectedAccordionItem, setSelectedAccordionItem] = useState<string | null>(null);
  const handleHeaderClick = (key: string, close: () => void) => () => {
    close();

    if (selectedAccordionItem === key) {
      return setSelectedAccordionItem(null);
    }

    return setSelectedAccordionItem(key);
  }

  useEffect(() => {
    if (selectedItem) {
      setSelectedAccordionItem(selectedItem);
    }
  }, [selectedItem]);

  useEffect(() => {
    // Make sure that expandable element always visible for user
    setTimeout(() => {
      accordionRef
        ?.current
        ?.querySelector?.(`#${selectedAccordionItem}`)
        ?.scrollIntoView?.({ block: "end", inline: "nearest", behavior: "smooth"});
    }, 500);
  }, [selectedAccordionItem, accordionRef]);

  return (
    <div className="w-full rounded first:-mt-3" ref={accordionRef}>
      <Disclosure defaultOpen >
        {({ close }) => (
          <>
            {
              items.map(({ title, content, key }) => (
                <div key={key} id={key}>
                  <Disclosure.Button
                    className={`
                      flex w-full rounded bg-gray-600 text-gray-50 mt-3 px-3 py-4 text-left text-base font-semibold items-center
                      ${selectedAccordionItem === key ? "border-b border-gray-500 rounded-b-none" : ""}
                    `}
                    onClick={handleHeaderClick(key, close)}
                  >
                    {title}
                    <IconFont
                      className="flex ml-auto"
                      name={selectedAccordionItem === key ? "arrow_up" : "arrow_down"}
                      size="md"
                    />
                  </Disclosure.Button>
                  {
                    selectedAccordionItem === key
                    && <Disclosure.Panel
                      className={`text-gray-50 bg-gray-600 px-3 py-4 rounded-b animated-accordion-open`}
                    >
                      {content}
                    </Disclosure.Panel>
                  }
                </div>
              ))
            }
          </>
        )}
      </Disclosure>
    </div>
  )
}
