import { decodeString } from "./utils/string.js";
import type { IOracleJob } from "./protos.js";

import axios from "axios";
import bs58 from "bs58";

export class CrossbarClient {
  readonly crossbarUrl: string;
  readonly verbose: boolean;

  static default(verbose?: boolean) {
    return new CrossbarClient("https://crossbar.switchboard.xyz", verbose);
  }

  constructor(crossbarUrl: string, verbose?: boolean) {
    this.crossbarUrl = new URL(crossbarUrl).origin;
    this.verbose = !!verbose;
  }

  async fetch(feedHash: string): Promise<any> {
    try {
      return await axios
        .get(`${this.crossbarUrl}/fetch/${feedHash}`)
        .then((resp) => resp.data);
    } catch (err) {
      if (!axios.isAxiosError(err)) throw err;

      const response = err.response;
      if (!response) throw err;

      // If response is outside of the 200 range, log the status and throw an error.
      if (this.verbose) console.error(`${response.status}: ${response.data}`);
      throw new Error(`Bad Crossbar status: ${response.status}`);
    }
  }

  async store(
    queueAddress: string,
    jobs: IOracleJob[]
  ): Promise<{ cid: string; feedHash: string; queueHex: string }> {
    try {
      // Try to decode the queueAddress to a Buffer so that we can send it in the expected format,
      // base58, to the Crossbar node.
      const queue = decodeString(queueAddress);
      if (!queue) throw new Error(`Unable to parse queue: ${queueAddress}`);

      return await axios
        .post(
          `${this.crossbarUrl}/store`,
          { queue: bs58.encode(queue), jobs },
          { headers: { "Content-Type": "application/json" } }
        )
        .then((resp) => resp.data);
    } catch (err) {
      if (!axios.isAxiosError(err)) throw err;

      const response = err.response;
      if (!response) throw err;

      if (this.verbose) console.error(`${response.status}: ${response.data}`);
      throw new Error(`Bad Crossbar response: ${response.status}`);
    }
  }
}
