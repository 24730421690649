import { Transition, Dialog } from "@headlessui/react";
import { Fragment } from "react";

import Icon from "../icon/Icon";
import IconFont from "../iconFont/IconFont";
import { twMerge } from "tailwind-merge";
interface Props {
  open: boolean;
  children: React.ReactNode;
  footer?: React.ReactNode;
  icon?: React.ReactNode;
  title?: string | React.ReactNode;
  classes?: {
    dialog?: string;
    scrollContainer?: string;
  };
  onClose: () => void;
  withHeading?: boolean;
}

export function BaseModal({
  open,
  children,
  footer,
  title,
  onClose,
  icon,
  classes,
  withHeading = true,
}: Props) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-950 opacity-60 transition-opacity" />
        </Transition.Child>

        <div data-id="base-modal-container" className={`fixed inset-0 z-50 w-screen`}>
          <div
            className={`flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0`}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={twMerge(
                  `relative flex transform flex-col gap-4 overflow-hidden rounded-lg bg-gray-800 p-6 text-left shadow-xl transition-all sm:mt-8 sm:w-full sm:max-w-[460px]`,
                  `${classes?.dialog ? classes?.dialog : ""}`,
                )}
              >
                {withHeading ? (
                  <div className="flex max-h-[var(--modal-header-height)] items-center justify-between">
                    <h5 className="flex gap-1.5">
                      {icon && <Icon className="text-gray-400" inheritStroke icon={icon} />}
                      {title}
                    </h5>
                    <div className="z-10 text-gray-50" role="button" onClick={onClose}>
                      <IconFont size="xl" name="close_md" />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div
                  data-id="modal-scroll-container"
                  className={`
                    relative 
                   
                    ${classes?.scrollContainer ? classes?.scrollContainer : ""}
                  `}
                >
                  {children}
                </div>
                {footer}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
