import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { Connection, Keypair, PublicKey } from "@solana/web3.js";
import bs58 from "bs58";
import { decryptSecretKey, encryptSecretKey } from "../sdk/utils";
import { NetworkContext } from "../../contexts/NetworkContext";
import TransactionHandler from "../sdk/transactionHandler";
import { RPC_WRITE_ENPOINTS } from "../sdk/constants";

export interface ISigner {
  publicKey: string;
  encryptedPrivate: string;
  solBalance: number;
}

export interface ISignersContext {
  signers: ISigner[];
  addSigner: (privateKey: string, pin: string, onError: Function, onSuccess: Function) => Promise<void>;
  removeSigner: (signer: ISigner) => void;
  clearSigners: () => void;
  getKeypair: (publicKey: string, pin: string) => Keypair;
  loadKeypair: (secretKey: string) => Keypair
  isOnSignersList: (signer: string) => boolean
  transactionHandler: TransactionHandler | undefined
}

export const SignerContext = createContext<ISignersContext>({} as ISignersContext);

interface Props {
  children: any;
}

export const SignerProvider = ({ children }: Props) => {
  const [signers, setSigners] = useLocalStorage("zeebit-admin-signers", "");
  const { client } = useContext(NetworkContext)
  const [transactionHandler, setTransactionHandler] = useState<TransactionHandler>()

  useEffect(() => {
    setTransactionHandler(TransactionHandler.load(
      RPC_WRITE_ENPOINTS, 
      {
        commitment: "processed",
        skipPreflight: true
      },
      5
    ))
  }, [])

  useEffect(() => {
    async function loadBalances(signers: ISigner[], connection: Connection) {
      const balances = await Promise.all((signers.map((signer) => connection.getBalance(new PublicKey(signer.publicKey)))))
      const updatedSigners = [...signers].map((signer, index) => {
        return {
          ...signer,
          solBalance: (balances[index] || 0) / Math.pow(10, 9) 
        }
      })

      setSigners(updatedSigners)
    }
    
    if (client == null) {
      return
    }
    
    if (signers == null || signers == "") {
      setSigners([]);
    } else {
      loadBalances(signers, client)
    }
  }, [client]);

  const loadKeypair = (secretKey: string): Keypair => {
    // TODO HANDLE SEED PHRASE

    return Keypair.fromSecretKey(bs58.decode(secretKey))
  }
  
  const isOnSignersList = useCallback((signer: string) => {
    // CHECK THIS IN FUTURE
    return true
  }, [])

  const addSigner = useCallback(async (privateKey: string, pin: string, onError: Function, onSuccess: Function) => {
      // CHECK VALID PRIVATE KEY
      try {
        const keypair = loadKeypair(privateKey)

        // CHECK PUBKEY ON LIST OF AUTHORITIES
        const pubkey = keypair.publicKey.toString()
        const balance = await client?.getBalance(keypair.publicKey)

        const signer: ISigner = {
          publicKey: pubkey,
          encryptedPrivate: encryptSecretKey(privateKey, pin),
          solBalance: (balance || 0) / Math.pow(10, 9),
        }

        const signersUpdated = [...signers];
        signersUpdated.push(signer);

        setSigners(signersUpdated);

        onSuccess(`Successfully added wallet: ${signer.publicKey}`)
      } catch (err) {
        console.error('ISSUE ADDING SIGNER', err)
        onError(err)
      }
    },
    [signers, client],
  );

  const removeSigner = useCallback(
    (signer: ISigner) => {
      if (signers.length == 1) {
        setSigners([])
        return
      }

      const signersUpdated = [...signers];
      const index = signersUpdated.findIndex((sig) => {
        return sig.publicKey == signer.publicKey;
      });
      const removed = signersUpdated.splice(index, 1);

      setSigners(removed);
    },
    [signers],
  );

  const getKeypair = useCallback((publicKey: string, pin: string) => {
    const signersArr: ISigner[] = Array.from(signers)
    
    // ENSURE SIGNER IN LOCAL STORAGE
    const signer = signersArr.find((signer) => {
      return signer.publicKey == publicKey
    })

    if (signer == null) {
      throw new Error("Signer not found")
    }

    // DECRYPT THE SECRET KEY
    const decryptedSecretKey = decryptSecretKey(signer.encryptedPrivate, pin)

    // LOAD KEYPAIR
    const keypair = loadKeypair(decryptedSecretKey)

    // ENSURE PUBKEY IS SAME AS LOCAL
    if (keypair.publicKey.toString() != signer.publicKey) {
      throw new Error(`Pubkey different after loading; Before: ${signer.publicKey} After: ${keypair.publicKey.toString()}`)
    }

    return keypair
  }, [signers])

  const clearSigners = useCallback(() => {
    setSigners([]);
  }, [signers]);

  return (
    <SignerContext.Provider
      value={useMemo(
        () => ({
          signers: signers,
          addSigner: addSigner,
          removeSigner: removeSigner,
          clearSigners: clearSigners,
          getKeypair: getKeypair,
          loadKeypair: loadKeypair,
          isOnSignersList: isOnSignersList,
          transactionHandler: transactionHandler
        }),
        [signers, addSigner, removeSigner, clearSigners, getKeypair, loadKeypair, isOnSignersList, transactionHandler],
      )}
    >
      {children}
    </SignerContext.Provider>
  );
};
