import { useNavigate } from "react-router-dom";
import Button from "../components/common/button/Button";
import Icon404 from "./../assets/icons/404.svg";
import IconDice404 from "./../assets/icons/Dice_404.svg";

interface IErrorPage {
  backHome: string;
}
const Error404Page = ({ backHome = `` }: IErrorPage) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col w-full h-full justify-center items-center gap-y-16">
      {/* ICONS */}
      <div className="flex relative w-full justify-center">
        <div className="w-full max-w-[692px] aspect-[692 / 293]">
          <Icon404 />
        </div>
        <div className="absolute h-[100%] aspect-square">
          <IconDice404 />
        </div>
      </div>

      {/* PAGE NOT FOUND / BACK HOME */}
      <div className="flex flex-col gap-y-6 text-center">
        <div className="flex flex-col gap-y-3 font-oxanium">
          <div className="font-bold text-xl text-gray-50">Page not found.</div>
          <div className="font-normal text-gray-300">
            Sorry, we couldn’t find the page you’re looking for.
          </div>
        </div>
        <div className="self-center">
          <Button
            size="sm"
            variant="gray"
            onClick={() => {
              navigate(backHome);
            }}
          >
            Back to home
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Error404Page;
